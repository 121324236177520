import React from 'react';
import PageLayout from '../components/PageLayout';
import {graphql} from 'gatsby';
import {useTranslation} from 'react-i18next';

const ContactsPage = () => {
  const {t} = useTranslation();

  return (
    <PageLayout lowContent>
      <main className='container bg-base-100 p-8'>
        <h1 className='text-4xl mb-4'>{t('contacts.title')}</h1>
        <div>
          <strong>{t('contacts.phone')}:</strong> +370 671 10191
        </div>
        <div>
          <strong>{t('contacts.working_hours')}:</strong> I–V 9:00-18:00
        </div>
        <div>
          <strong>{t('contacts.address')}:</strong> Kalvarijų g. 1, Vilnius 06117
        </div>
        <div>
          <strong>Nors, MB</strong>
        </div>
        <div>
          <strong>{t('contacts.email')}:</strong> info@fortula.app
        </div>
      </main>
    </PageLayout>
  );
};

export default ContactsPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}, ns: {in: ["index"]}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
